<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="card-title">
        <div
          class="bg-white rounded border p-1"
          style="padding: 6px 0; cursor: pointer; color: black"
        >
          <div class="d-flex">
            <div class="mr-1">
              <router-link :to="'/profile/' + candidate_id">
                <img
                  style="border-radius: 50%"
                  :src="decodeURIComponent(candidate_photo)"
                  alt="photo"
                  width="40"
                />
              </router-link>
            </div>
            <div style="padding-top: 8px">
              {{ candidate_name }}'s Availability :
            </div>
          </div>
        </div>
      </div>
      <div>
        <DatePicker
          class="mt-3"
          v-model="start_date"
          type="date"
          @on-change="SearchData"
          :clearable="true"
          placeholder="Select date"
          style="width: 100%"
        >
        </DatePicker>
      </div>
    </div>
    <div class="card">
      <div class="row p-1">
        <div
          class="col-md-4 text-center"
          v-for="(times, date) in availableSlots"
          :key="date"
        >
          <p
            class="p-1 rounded border"
            style="background-color: #e7e7e7; color: black; font-weight: bold"
          >
            {{ date }}
          </p>
          <div class="row" v-if="times.length">
            <div class="col-6" v-for="(time, index) in times" :key="index">
              <p
                class="bg-light rounded border"
                style="padding: 6px 0; cursor: pointer; color: black"
                @click="openModal(time, date)"
              >
                {{ time.slice(0, 5) }}
              </p>
            </div>
          </div>
          <div v-else>
            <p>There are no free time slot</p>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="modalShow" title="Create Schedule" hide-footer>
      <b-form @submit.prevent="createSchedule">
        <b-form-group label="Starting Date" label-for="starting_date">
          <b-form-input
            id="starting_date"
            v-model="scheduleData.starting_date"
            type="date"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Schedule Time">
          <b-form-input
            v-model="scheduleData.starting_time"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Summary" label-for="summary">
          <b-form-input
            id="summary"
            v-model="scheduleData.summary"
            placeholder="Enter summary"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Location" label-for="location">
          <b-form-input
            id="location"
            v-model="scheduleData.location"
            placeholder="Enter location"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Description" label-for="description">
          <b-form-textarea
            id="textarea-rows"
            v-model="scheduleData.description"
            placeholder="Write a description about the event"
            rows="4"
          ></b-form-textarea>
        </b-form-group>

        <b-button type="submit" variant="primary">Create Schedule</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BFormTextarea,
  BForm,
  BButton,
  BPagination,
  BBadge,
} from "bootstrap-vue";
import { HasError, AlertError } from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        company_id: this.$route.params.companyId,
        date: "",
      }),
      scheduleData: new Form({
        proposeId: this.$route.params.proposedCandidateId,
        companyId: this.$route.params.companyId,
        summary: "",
        location: "",
        description: "",
        starting_time: "",
        starting_date: "",
      }),
      candidate_id: this.$route.params.candidateId,
      candidate_name: this.$route.params.candidateName,
      candidate_photo: this.$route.params.candidatePhoto,
      start_date: "",
      id: this.$route.params.proposedCandidateId,
      availableSlots: {},
      modalShow: false,
    };
  },
  components: {
    BBadge,
    BCard,
    BCardText,
    BLink,
    BMedia,
    BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BMediaBody,
    HasError,
    AlertError,
    BPagination,
    BFormTextarea,
    BCardBody,
  },
  mounted() {
    this.getAvailableSlots();
  },
  methods: {
    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return null;
    },

    getAvailableSlots() {
      this.form.date = this.formatDate(this.start_date);
      this.form
        .post("/app/get-available-time/" + this.id)
        .then((res) => {
          this.availableSlots = res.data.availableSlots;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    openModal(time, selectedDate) {
      this.scheduleData.starting_time = time.slice(0, 8);
      this.scheduleData.starting_date = selectedDate;
      this.modalShow = true;
    },

    closeModal() {
      this.modalShow = false;
    },

    createSchedule() {
      this.scheduleData
        .post("/app/book-interview-schedule")
        .then((res) => {
          this.s(res.data.message);
          this.closeModal();
          this.getAvailableSlots();
        })
        .catch((e) => {
          this.e(e.response.data.message);
          this.closeModal();
        });
    },

    SearchData: _.debounce(function () {
      this.getAvailableSlots();
    }, 10),
  },
};
</script>

<style scoped></style>
